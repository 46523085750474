import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import useJsonParse from "../../../hooks/useJsonParse";
import ContinueButton from "../../UI/continueButton/ContinueButton";
import SwiperComponent from "../../UI/swiperComponent/SwiperComponent";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./TargetBody.module.scss";

const TargetBody = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.targetBody;
    const themeData = data.themeData
    const bodyImages = useJsonParse(pageInfo.cards);
    const defaultSlide = pageInfo.smallVariant ? 1 : 5;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    const [currentSlide, setCurrentSlide] = useState(defaultSlide);

    const changeSlide = value => {
        setCurrentSlide(value)
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_target_body_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_target_body_tapped', {answer: currentSlide + 1});
        const answer = new Map();
        answer.set("key", "target_body");
        answer.set("value", currentSlide + 1);
        nextStep(answer);
    }

    return (
        <>
            <div className={mainContainerClass}>
                <h1 className={styles.cardTitle}>{strings.goal.targetBody.titleStart}<span>{strings.goal.targetBody.titleDesired}</span>{strings.goal.targetBody.titleEnd}</h1>
                <div className={styles.bodySlider}>
                    <SwiperComponent data={bodyImages} defaultSlide={defaultSlide} currentSlide={currentSlide} withoutBorder={pageInfo.withoutBorder} sliderTitle={'Target'} changeSlide={changeSlide}/>
                </div>
                <div className={`${styles.sliderLabels} flex-between`}>
                    <span>{ pageInfo.smallVariant ? strings.goal.targetBody.medium : strings.goal.targetBody.plump }</span>
                    <span className={`${pageInfo.withMiddleLabel ? styles.rightSpace : ''}`}>
                        { pageInfo.smallVariant ? strings.goal.targetBody.curvy : strings.goal.targetBody.skinny }
                    </span>
                    { pageInfo.withMiddleLabel && (<span>{ strings.goal.targetBody.skinny}</span>)}
                </div>
                <ContinueButton
                    nextStep={clickContinue}
                    theme={themeData.continueButton}
                />
            </div>
        </>
    )
}   

export default TargetBody;