import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import TextCard from "../../UI/textCard/TextCard";
import strings from "../../../assets/localization/strings.jsx";

import styles from "./Children.module.scss";

const Children = props => {
    const { nextStep, data } = props;
    const pageInfo = data.settings.children
    const textInfo = strings.lifestyle.children
    const themeData = data.themeData
    const [cards, setCards] = useState(pageInfo.cards);
    const [activeCard, setActiveCard] = useState();
    const [disableButton, setDisableButton] = useState(true);
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    const selectCard = (card) => {
        setActiveCard(card);
    }

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_children_appeared');
    }, [])

    useEffect(() => {
        if(activeCard) {
            setDisableButton(false);
        }
        return () => setDisableButton(true);
    }, [activeCard])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_children_tapped', {answer: activeCard.title});
        const answer = new Map();
        answer.set("key", "has_children");
        answer.set("value", activeCard.title);
        nextStep(answer);
    }

    return (
        <div className={mainContainerClass}>
            <h1 className={styles.title}>{textInfo.title}</h1>
            <span className={`${cards.length > 2 ? styles.marginTitleUp : styles.marginTitle} componentSubtitle`}>
                {textInfo.subtitle}
            </span>
            { cards?.map(card => ( 
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard
                        borderColor={themeData.checkboxCardColor}
                        card={card}
                        selectCard={selectCard}
                        activeCard={activeCard}
                    />
                </div> )) 
            }
            <ContinueButton
                nextStep={clickContinue}
                disableButton={disableButton}
                theme={themeData.continueButton}
            />
        </div>
    )
}   

export default Children;