import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import danceStylesData from "../../../core/static/plan/danceStyles.json";
import ContinueButton from "../../UI/continueButton/ContinueButton";

import styles from "./DanceStyles.module.scss";
import strings from "../../../assets/localization/strings.jsx";
const DanceStyles = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const [countSelectedCards, setCountSelectedCards] = useState(0);
    const [cards, setCards] = useState(danceStylesData);
    const [activeCard, setActiveCard] = useState(false);
    const [activeCards, setActiveCards] = useState([]);
    const [disableButton, setDisableButton] = useState(true);

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_dance_styles_appeared');
    }, [])

    const selectCard = (card) => {
        if(activeCard) {
            setCountSelectedCards(0);
        }
        setActiveCard(false);
        setCards(cards.map(el => {
            if(el.id === card.id) {
                if(countSelectedCards < 4) {
                    if(!el.checked) {
                        setActiveCards((prev => [...prev, card]))
                        setCountSelectedCards(countSelectedCards + 1);
                        return {...el, checked: !el.checked};
                    } else {
                        setCountSelectedCards(countSelectedCards - 1);
                        return {...el, checked: false};
                    }
                } else {
                    if(el.checked) {
                        const removeCardIndex = activeCards.findIndex(el => el.id === card.id);
                        setActiveCards([...activeCards.slice(0, removeCardIndex), ...activeCards.slice(removeCardIndex + 1)])
                        setCountSelectedCards(countSelectedCards - 1);
                        return {...el, checked: false};
                    } else {
                        return el;
                    }
                }
            } else {
                return el;
            }
        }))
    }

    const selectNoCard = () => {
        setActiveCard(!activeCard);
        amplitude.getInstance().logEvent('button_onboarding_dance_styles_tapped', {answer: 'I don’t know these styles'});
        setCountSelectedCards(1);
    }

    useEffect(() => {
        if(countSelectedCards !== 0 || activeCard) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [countSelectedCards])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_dance_styles_tapped', {answer: activeCards.map(card => { return card.title })});
        const answer = new Map();
        answer.set("key", "dance_styles");
        answer.set("value", activeCards.map(card => { return card.title }));
        nextStep(answer);
    }

    const cardContainerActiveClass = `${styles.activeCard} ${styles[`${themeData.checkboxCardColor}ActiveCard`]}`

    return (
        <div className={`${styles.mainContainer} flex-column`}>
            <h1 className={styles.title}>{strings.plan.styles.title}</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>{strings.plan.styles.subtitle}</span>
            <div className={styles.cardsContainer}>
                { cards?.map(card => (
                    <div key={card.id} className={`${styles.cardWrapper} ${card.checked ? cardContainerActiveClass : ''} flex-column cardContainer ${themeData.checkboxCardColor}CardContainer`} onClick={() => selectCard(card)}>
                        <p className={`${styles.cardContent} cardTitle`}>{ strings.plan.styles.labels[card.localID] }</p>
                    </div>
                )) }
            </div>
            <button disabled={!!activeCard} className={`${styles.button} ${!activeCard ? styles.activeButton : ''}`} onClick={selectNoCard}>{strings.plan.styles.donotknow}</button>
            <ContinueButton
                nextStep={clickContinue}
                disableButton={disableButton}
                theme={themeData.continueButton}
            />
        </div>
    )
}   

export default DanceStyles;