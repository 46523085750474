import React from "react";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import styles from './CustomStepper.module.scss';
import LazyImage from "../lazyImage/LazyImage";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        height: 4,
        zIndex: 1,
        background: "linear-gradient(0deg, rgba(221,55,135,1) 0%, rgba(253,108,89,1) 100%)",
    },
    [`&::after`]: {
        left: 0,
        position: 'absolute',
        top: '50%',
        height: '2px',
        backgroundColor: '#E8E8E8'
    }
}));

const LightPurpleBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        height: 4,
        zIndex: 1,
        background: "#B826F6",
    },
    [`&::after`]: {
        left: 0,
        position: 'absolute',
        top: '50%',
        height: '2px',
        backgroundColor: '#E8E8E8'
    }
}));

const PurpleBorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        height: 4,
        zIndex: 1,
        background: "#7D8BF6",
    },
    [`&::after`]: {
        left: 0,
        position: 'absolute',
        top: '50%',
        height: '2px',
        backgroundColor: '#E8E8E8'
    }
}));

const checkedStyle = (color, props) => {
    switch(color) {
        case "pink":
            return <BorderLinearProgress {...props} />
        case "purple":
            return <PurpleBorderLinearProgress {...props} />
        case "lightPurple":
            return <LightPurpleBorderLinearProgress {...props} />
        default:
            return <BorderLinearProgress {...props} />
    }
}

function CustomStepper(props) {
    const { steps, current, progress, theme } = props;

    const ProgressBar = ({ current, step, progress }) => {
        let value = 0;
        if(current+1 === step) {
            value = progress
        } else if(current >= step) {
            value = 100
        }
        return checkedStyle(theme, { variant: "determinate", value: value, className: styles.linearProgress } )
    }
  
    function renderStep(step, key) {
        const { current, progress } = this;
        const done = key < current + 1;
        const completedStepClass = `${styles.completedStep} ${styles[`${theme}CompletedStep`]}`

        return (
            <li className={styles.stepper__step} key={key}>
                <div className={styles.labelContainer}>
                    <div className={`${styles.stepContainer} ${done ? completedStepClass : ''}`}>
                        <LazyImage
                            srcWebp={!done ? step.inactiveIconWebp : step.activeIconWebp}
                            src={!done ? step.inactiveIcon : step.activeIcon}
                            alt=""
                        />
                    </div>
                </div>
                {!!key && <ProgressBar current={current} step={key} progress={progress} />}
            </li>
        )
    }

    return (
        <ul className={styles.stepper}>
            {steps.map(renderStep, { current, progress })}
        </ul>
    )
}

export default CustomStepper;