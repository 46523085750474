import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import amplitude from 'amplitude-js';
import sha256 from 'crypto-js/sha256';
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import moment from "moment/moment";

import styles from "./CheckoutForm.module.scss";
import "./CheckoutForm.scss";

const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const netToken = 'EAACZBgaOc86EBOZBtOPqwuocghfnngNyTZA20iQcbMGWXTM8lPutMXk6gsIw3csDiZAqSNPCHejHLSHg3TJkea6ZCVIwJJaRcEcV8vXKxk6TYGuDiWBfFaMWAfokNL1mrhzoE01qhaTE2GH42aDrdMKpdwnoCsFz6Mvd9NB0neyF9Oz8HxVIux47RYtW7OWvdWAZDZD';
const tiktokApiToken = 'e9cb49ddaa71504c0628507456187bbfb9368637';

import strings from "../../assets/localization/strings.jsx";
import LazyImage from "../../components/UI/lazyImage/LazyImage";
const style = {
    base: {
        iconColor: '#E15768',
        color: '#1E1E27',
        fontFamily: 'Futura',
        '::placeholder': {
            fontWeight: 500,
            fontSize: 13,
            color: '#BBBBBB'
        },
    },
    invalid: {
        color: '#E15768',
    }
}

const appleButtonStyle = {
    paymentRequestButton: {
      height: '48px',
      width: '180px',
      borderRadius: '8px'
    }
}

const cardNumberStyle = {
    style: style,
    showIcon: true,
    placeholder: 'Card number',
};

const cardExpiryStyle = {
    style: style,
    placeholder: 'MM/YY',
};

const cardCvcStyle = {
    style: style,
};

const paypalId = 'Af7bctxkCUaREhFJ0BemXCIe_wjt63BQZ6lAPH58TSx-ew4SMvdCAXT5vKgf0iiAPGmGGo9TGSnsRddE'
const testPaypalId = 'AVMWUzb4KSB_QhCJ6MAB72V7RgeM4KVLPSDcqhDDi6HPfXLB70erhueUmevRgsY74_kXlO4_ucmI75pP'

const CheckoutForm = props => {
    localStorage.removeItem('emailInfo');
    localStorage.removeItem('subscriptionId');
    const { period, buttonText, setWaitingPopup, liveMode, domain, pageInfo } = props;
    const stripe = useStripe();
    const elements = useElements();
    const [email, setEmail] = useState(null);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [cardHolder, setCardHolder] = useState(null);
    const [error, setError] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [succeeded, setSucceeded] = useState(null);
    const [paypalClientId, setPaypalClientId] = useState(liveMode ? paypalId : testPaypalId);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const navigate = useNavigate();
    const [ipAddress, setIPAddress] = useState('')
    let subscriptionResult = {}

    useEffect(() => {
            fetch('https://api64.ipify.org?format=json')
              .then(response => response.json())
              .then(data => setIPAddress(data.ip))
              .catch(error => console.log(error))
    }, []);

    useEffect(() => {
        fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/create_payment_intent", {
            method: "POST",
            body: JSON.stringify({'livemode': liveMode}),
            }).then(async (result) => {
            const { client_secret } = await result.json();
            setClientSecret(client_secret);
            localStorage.setItem('client_code', client_secret)
        });

        return () => {
            localStorage.removeItem('client_code')
        }
    }, []);

    useEffect(() => {
        if(succeeded !== null && succeeded) {
            localStorage.setItem('emailInfo', email);
            localStorage.setItem('subscriptionId', subscriptionId);
            navigate('/registration');
        } else if(succeeded !== null && !succeeded) {
            navigate('/failure');
        }
    }, [succeeded])

    const handleSubmit = async (e) => {
        facebookEvent();
        e.preventDefault();
        setDisabled(true);
        if (!stripe || !elements) return;

        setWaitingPopup(true)
        const clientCode = localStorage.getItem('client_code')
        const {error: stripeError, setupIntent} = await stripe.confirmCardSetup(
            clientCode,
            {
                payment_method: {
                    type: 'card',
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        email: email,
                    },
                },
            }
        );

        facebookCapi('AddPaymentInfo', `paymentinfo.${getCookie('id')}`, email, period?.stripe_card_id);
        amplitude.getInstance().logEvent('cookies', {cookies: document.cookie});

        if (stripeError) {
            setError(`Payment failed ${stripeError.message}`);
            setDisabled(false);
            setWaitingPopup(false)
        } else {
            setError(null);
            await makeSubscription(setupIntent['payment_method'], email)
        }
    };

    const createSubscription = async (priceId, paymentMethodId, email) => {
        await fetch("https://b7lwa365dl.execute-api.us-east-1.amazonaws.com/dev/create_subscription", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                priceId: priceId,
                paymentMethodId: paymentMethodId,
                email: email,
                amplitude_user_id: amplitude.getInstance().options.userId,
                location: localStorage.getItem('search'),
                onboarding_id: localStorage.getItem('onboarding_id'),
                nutrition: localStorage.getItem('with_nutrition'),
                type_of_diet: localStorage.getItem('type_of_diet'),
                livemode: liveMode
            }),
        }).then(async (response) => {
            console.log("createSubscription result");
            let resultBody = response.json();
            await resultBody.then( async (json) => {
                console.log(json)
                subscriptionResult = {id: json.subscription, status: json.status}
                localStorage.setItem('paymentMethodType', "stripe");
                localStorage.setItem('customer_id', json.customerId);
                localStorage.setItem('paymentMethodId', paymentMethodId);
                localStorage.setItem('liveMode', liveMode);
                return json
            });
        });
    }

    const makeSubscription = async (paymentMethod, paymentEmail) => {
        await createSubscription(
            period?.stripe_card_id,
            paymentMethod,
            paymentEmail,
        )
        if(subscriptionResult.id !== null && subscriptionResult.status !== null && (subscriptionResult.status === 'active' || subscriptionResult.status === 'trialing')){
            await sendResultEvent('subscribe', paymentEmail, subscriptionResult.id)
            amplitude.getInstance().setUserProperties({"subscrId": subscriptionResult.id, "email": paymentEmail})
            amplitude.getInstance().logEvent('payment_successful', {product: period?.title});
            setSucceeded(true)
        } else{
            setSucceeded(false)
        }
        setDisabled(false);
        setWaitingPopup(false)
    }

    const sendResultEvent = async (eventName, emailData, subscriptionId) => {
        const eventSystems = ['Subscribe', 'Purchase']
        const eventId = `${eventName}.${getCookie('id')}`
        setSubscriptionId(subscriptionId);
        const trackData = {
            subscription_id: subscriptionId,
            value: period?.total,
            currency: 'USD'
        }

        const discountStatus = localStorage.getItem('discountStatus');

        TiktokPixel.track('CompletePayment', {event_id: eventId, value: period?.total, currency: 'USD', quantity: 1});
        const payload = {

                                            "pixel_code": "CP2RST3C77UF83EV1CUG",
                                            "event_id": eventId,
                                            "event": "CompletePayment",
                                            "timestamp": new Date(Date.now()),
                                            "context": {
                                                user: {
                                                    external_id: sha256(getCookie('id')).toString()
                                                },
                                                user_agent: navigator.userAgent,
                                             },
                                            "properties": {
                                                     "contents": [
                                                         {
                                                             "price": period?.total,
                                                             "quantity": 1,
                                                             "content_type": "subscription",
                                                             "content_id": period?.title
                                                         }
                                                     ],
                                                     "currency": "USD",
                                                     "value": period?.total
                                            }
                                           };

                                           const ttc = document.cookie.split(';').filter(c => c.includes('tiktok_click_id=')).map(c => c.split('tiktok_click_id=')[1]).join();
                                           const ttp = document.cookie.split(';').filter(c => c.includes('_ttp=')).map(c => c.split('_ttp=')[1]).join();

                                           if(ttc) {
                                             payload.context.user.ttclid = ttc;
                                           }

                                           if(ttp) {
                                             payload.context.user.ttp = ttp;
                                           }

                                          try{
                                          fetch('https://smvcqm8fu5.execute-api.us-east-1.amazonaws.com/dev/tiktok_pixel_event', {
                                                                                       method: "POST",
                                                                                       headers: {
                                                                                         'Accept': 'application/json',
                                                                                         'Content-Type': 'application/json'
                                                                                       },
                                                                                       body: JSON.stringify(payload)
                                            });
                                          } catch(e){
                                          console.log("tiktok track error" + e)
                                          }

        if(discountStatus && discountStatus === 'trial'){
        facebookCapi("StartTrial", eventId, emailData, period?.stripe_card_id, period?.total)
        ReactPixel.track("StartTrial", trackData, {eventID: eventId});
        } else{
        eventSystems.map(action => {
                    facebookCapi(action, eventId, emailData, period?.stripe_card_id, period?.total)
                    ReactPixel.track(action, trackData, {eventID: eventId});
                })
        }

    }

    const handleChange = async (event) => {
        setDisabled(false);
        setError(event.error ? event.error.message : "");
    };

    const paypalSubscribe = (data, actions) => {
        facebookCapi('AddPaymentInfo', `paymentinfo.${getCookie('id')}`, null, period?.paypal_card_id);
        return actions.subscription.create({
            plan_id: period.paypal_card_id,
        });
    };

    const paypalOnApprove = async (data, details) => {
    await fetch("https://1fqev002he.execute-api.us-east-1.amazonaws.com/dev/paypal_subscription_data", {
                        method: "POST",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            priceId: period?.paypal_card_id,
                            email: email,
                            amplitude_user_id: amplitude.getInstance().options.userId,
                            location: localStorage.getItem('search'),
                            onboarding_id: localStorage.getItem('onboarding_id'),
                            subscr_id: data.subscriptionID,
                            nutrition: localStorage.getItem('with_nutrition'),
                            type_of_diet: localStorage.getItem('type_of_diet')
                        }),
                    }).then(async (response) => {
                        console.log("paypal subscription data result");
                        let resultBody = response.json();
                        await resultBody.then( async (json) => {
                            console.log(json)
                            return json
                        });
                    });
        amplitude.getInstance().logEvent('payment_successful', {product: period?.title});
        sendResultEvent('paymentinfo', null, data.subscriptionID).then(() => {
            localStorage.setItem('emailInfo', email);
            localStorage.setItem('subscriptionId', data.subscriptionID);
            localStorage.setItem('paymentMethodType', "paypal");
            setWaitingPopup(false)
            navigate('/registration');
        })
    };

    const facebookEvent = () => {
        const eventId = `paymentinfo.${getCookie('id')}`
        ReactPixel.track('AddPaymentInfo', {eventID: eventId});
    }

    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
          "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    function facebookCapi(name, eventId, emailData, productId, total) {
        const payload = {
            data: [
                {
                    event_name: name,
                    event_time: moment().unix(),
                    data_processing_options: ["LDU"],
                    data_processing_options_country: 0,
                    data_processing_options_state: 0,
                    action_source: "website",
                    event_id: eventId,
                    user_data: {
                            subscription_id: productId,
                            client_user_agent: navigator.userAgent,
                            external_id: sha256(getCookie('id')).toString(),
                            client_ip_address: ipAddress
                            // ln: sha256(ln).toString(),
                            // fn: sha256(fn).toString(),
                    }
                }
            ]
        }

        if(emailData) {
            payload.data[0].user_data.em = [ sha256(emailData).toString()]
        }

        if(total) {
            payload.data[0].custom_data = {
                "currency": "USD",
                "value": total
            }
        }

        //const fbc = document.cookie.split(';').filter(c => c.includes('_fbc=')).map(c => c.split('_fbc=')[1]).join();
        const fbc = localStorage.getItem('fbclid');
        const fbp = document.cookie.split(';').filter(c => c.includes('_fbp=')).map(c => c.split('_fbp=')[1]).join();

        if(fbc) {
            payload.data[0].user_data.fbc = fbc;
        }

        if(fbp) {
            payload.data[0].user_data.fbp = fbp;
        }

        // save purchase event payload to local storage?
        localStorage.setItem('fcapi_purchase_payload', JSON.stringify(payload))
        // or send event to server?
        fetch(`https://graph.facebook.com/v10.0/${(domain.includes('net') ? '1085241235883188' : '239948538703054')}/events?access_token=${domain.includes('net')? netToken: token}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
    }

    useEffect(() => {
        if (stripe && period) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: period?.title,
                    amount: period?.trial_amount ?? period?.amount,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
    
            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(pr);
                }
            });

            pr.on('paymentmethod', async (e) => {
                if (e.paymentMethod) {
                    const paymentEmail = e.paymentMethod.billing_details.email;
                    if(paymentEmail !== null && paymentEmail !== undefined){
                        setEmail(paymentEmail);
                    }
                    const clientCode = localStorage.getItem('client_code')
                    facebookCapi('AddPaymentInfo', `paymentinfo.${getCookie('id')}`, paymentEmail, period?.stripe_card_id);
                    if (clientCode) {
                        const {error: stripeError, setupIntent} = await stripe.confirmCardSetup(
                            clientCode,
                            { payment_method: e.paymentMethod.id, },
                            { handleActions: false, }
                        );
                        if (stripeError) {
                            console.log(stripeError);
                            e.complete('fail');
                        } else {
                            e.complete('success');
                            if (setupIntent.status === 'requires_action') {
                                let {error: stripeError} = await stripe.confirmCardSetup(clientSecret);

                                if (stripeError) {
                                    return;
                                }
                            }
                            await makeSubscription(e.paymentMethod.id, paymentEmail)
                        }
                    } else{
                        console.log(" clientSecret error");
                    }
                } else{
                    console.log(" paymentmethod error");
                }
            });
        }
    }, [stripe, period]);

    const buttonClass = `${styles.payButton} ${disabled ? styles.disableButton : ''} ${styles[`${pageInfo.style}Campaign`]}`
    const formClass = `${styles.paymentContainer} ${styles[`${pageInfo.style}PaymentContainer`]}`

    return (
        <>
           {(pageInfo.paymentButtons === undefined || pageInfo.paymentButtons === 'top') && <div className={`${styles.buttonsContainer}`} onClick={facebookEvent}>
                <PayPalScriptProvider options={{ "client-id": paypalClientId, vault:true }}>
                    <PayPalButtons
                        style={{ layout: "horizontal", width: 180, height: 48, borderRadius: 8 }}
                        createSubscription={(data, details) => paypalSubscribe(data, details)}
                        onApprove={(data, details) => paypalOnApprove(data, details)}
                        onClick={() => amplitude.getInstance().logEvent('button_onboarding_payment_paypal_tapped')}
                    />
                </PayPalScriptProvider>
                {paymentRequest && <PaymentRequestButtonElement options={{paymentRequest, style: appleButtonStyle}} onClick={() => amplitude.getInstance().logEvent('button_onboarding_payment_applepay_tapped')}/>}
            </div> }
             {(pageInfo.paymentButtons === undefined || pageInfo.paymentButtons === 'top') && <p className={styles.textOr}>{strings.payment.or}</p>}



            <form className={formClass} id="payment-form" onSubmit={handleSubmit}>
            <div className={styles.cardClass}>
             <div className={`${styles.labelsContainer} flex-between`}>
                            <div className={`${styles.imagesContainer} flex-row`}>
                                <LazyImage
                                    src="/images/icons/payment.png"
                                    srcWebp="/images/icons/payment.webp"
                                    alt=""
                                />
                            </div>
                        </div>
                <div className={`${styles.firstLine} flex-row`}>
                    <CardNumberElement id="card-element" options={cardNumberStyle} className={`${styles.field} ${styles.fieldCardName}`} onChange={handleChange}/>
                    <CardExpiryElement id="card-element" options={cardExpiryStyle} className={`${styles.field} ${styles.fieldMc}`} onChange={handleChange}/>
                    <CardCvcElement id="card-element" options={cardCvcStyle} className={`${styles.field} ${styles.fieldCvc}`} onChange={handleChange}/>
                </div>
                {error && (
                    <div className={styles.errorContainer}>
                        <p id="error_form_field" className={styles.errorFormField}>{error}</p>
                    </div>
                )}
                <div className={styles.inputCardHolder}>
                    <input id="name"
                           onChange={(e => setCardHolder(e.target.value))}
                           name="name"
                           className={`${styles.field} ${styles.fieldCardHolderName} ${styles.marginFieldHolder}`}
                           placeholder={strings.payment.cardname} pattern="^[А-Яа-яЁёA-Za-z\s]+$"
                    />
                </div>
                <div className={styles.inputEmail}>
                    <input id="email" onChange={e => setEmail(e.target.value)} value={email} name="email"
                           className={`${styles.field} ${styles.fieldCardHolderName} ${styles.marginEmail}`}
                           pattern="[А-Яа-яЁёA-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" placeholder="E-mail"
                    />
                </div>
               </div>
                <p className={`${styles.message} ${styles.marginMessage}`}>{strings.payment.after}</p>
                <button className={buttonClass} disabled={disabled} id="submit">
                    {(pageInfo.paymentButtonLock !== undefined && pageInfo.paymentButtonLock === true) && <img className={styles.buttonImage} src="/images/icons/continue_lock.png" />}
                    <span className={styles.buttonText}>{ buttonText }</span>
                    <span className={styles.pulseButtonRings}></span>
                    <span className={styles.pulseButtonRings}></span>
                    <span className={styles.pulseButtonRings}></span>
                </button>

                 {pageInfo.paymentButtons !== undefined && pageInfo.paymentButtons === 'bottom' && <p className={styles.textOr}>{strings.payment.or}</p>}

                {pageInfo.paymentButtons !== undefined && pageInfo.paymentButtons === 'bottom' && <div className={`${styles.buttonsContainer}`} onClick={facebookEvent}>
                               <PayPalScriptProvider options={{ "client-id": paypalClientId, vault:true }}>
                                   <PayPalButtons
                                       style={{ layout: "horizontal", width: 185, height: 48, borderRadius: 8 }}
                                       createSubscription={(data, details) => paypalSubscribe(data, details)}
                                       onApprove={(data, details) => paypalOnApprove(data, details)}
                                       onClick={() => amplitude.getInstance().logEvent('button_onboarding_payment_paypal_tapped')}
                                   />
                               </PayPalScriptProvider>
                               {paymentRequest && <PaymentRequestButtonElement options={{paymentRequest, style: appleButtonStyle}} onClick={() => amplitude.getInstance().logEvent('button_onboarding_payment_applepay_tapped')}/>}
                           </div> }

                {(period?.next_period_price !== null && period?.next_period_price !== undefined)
                    && (
                        <p className={`${styles.buttonMessage}`}>
                            {strings.payment.afterFirst.after}
                            {strings.paywall.periods[period?.period_label]}
                            ${period?.next_period_price}
                            {strings.payment.afterFirst.per}
                            {strings.paywall.periods[period?.period_label]}
                        </p>
                    )}
                { !pageInfo.greenTheme && (<p className={styles.buttonMessage}>{strings.payment.cancelAny}</p>)}
                <div className={`${styles.linksContainer} flex-between`}>
                    <a href="https://everdance.app/policy" target="_blank">Privacy Policy</a>
                    <a href="https://everdance.app/terms"  target="_blank">Terms&Service</a>
                </div>
            </form>

        </>
    );
}

export default CheckoutForm;
