import { useState, useMemo, useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import Picker from "../../UI/picker/Picker";
import SwitcherComponent from "../../UI/switcher/Switcher";
import styles from "./GoalWeight.module.scss";
import AboutInput from "../../UI/aboutInput/AboutInput";
import strings from "../../../assets/localization/strings.jsx";

const lbsWeight = Array.from(Array(441).keys()).slice(55);
const kgWeight =  Array.from(Array(201).keys()).slice(25);

const GoalWeight = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const currentWeight = localStorage.getItem('currentWeight')
    const [weight, setWeight] = useState(null);
    const [pickerWeight, setPickerWeight] = useState(null);
    const [pickerData, setPickerData] = useState(lbsWeight);
    const [selected, setSelected] = useState(data.language === "ru" ? 2 : 1);
    const [disableButton, setDisableButton] = useState(true);
    const [inputLbs, setInputLbs] = useState('');
    const [inputKg, setInputKg] = useState('');
    const [buttonStyles, setButtonStyles] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    const pageInfo = data.settings.goalWeight
    const manualInput = pageInfo.manualInput
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
        setDisableButton(true);
        amplitude.getInstance().logEvent('screen_onboarding_goal_weight_appeared');
        return () => {
            setDisableButton(true);
        }
    }, [])

    const options = useMemo(() => [
        { value: 1, text: strings.about.goalWeight.lbs },
        { value: 2, text: strings.about.goalWeight.kg }
    ], []);

    const changePickerWeight = data => {
        setWeight(data + (selected === 1 ? 55 : 25))
        setDisableButton(false);
    }

    function compareWithCurrentWeight(value) {
        if(parseFloat(currentWeight) > parseFloat(value)) return false

        setErrorMessage(strings.about.errors.errorCurrent);
        setDisableButton(true);
        return true
    }

    const changeInputWeight = data => {
        setErrorMessage(data.errorMessage);
        setDisableButton(data.disableButton);
        if (!data.disableButton) {
            if(data.inputType === strings.about.goalWeight.kg){
                if(compareWithCurrentWeight(data.value)) return

                setInputKg(data.value)
                setWeight(data.value)
            } else {
                let calcValue = (data.value / 2.20462).toFixed()
                if(compareWithCurrentWeight(calcValue)) return

                setInputLbs(data.value)
                setWeight(calcValue)
            }
        }
    }

    useEffect(() => {
        if(selected === 1) {
            if(!manualInput) {
                if (!weight) {
                    setWeight(145);
                } else {
                    setPickerData(lbsWeight);
                    setWeight(weight * 2.20462);
                }
            } else {
                setInputLbs(weight ? (weight * 2.20462).toFixed() : '')
            }
        } else {
            if(!manualInput) {
                setPickerData(kgWeight);
                setWeight(weight / 2.20462);
            } else {
                setInputKg(weight)
            }
        }
    }, [selected])

    useEffect(() => {
        if(manualInput) return;

        if(!weight) {
            setPickerWeight(70)
        } else {
            setPickerWeight(pickerData.findIndex(el => el === Math.trunc(weight)))
        }
    }, [weight]);

    const inputOnFocus = () => {
        setTimeout(() => {
            setButtonStyles({ position: 'relative', marginTop: '45px', left: 'unset' })
        }, 100);
    };

    const inputOnBlur = () => {
        setTimeout(() => {
            setButtonStyles({})
        }, 200)
    };

    const clickContinue = () => {
        let value = 0
        let valueKG = 0
        let storageValue = ''
        if(selected === 1){
            value = manualInput ? inputLbs : Math.trunc(weight * 2.20462)
            valueKG = manualInput ? inputLbs / 2.20462 : weight / 2.20462
            storageValue = strings.about.goalWeight.lbs
        } else {
            value = manualInput ? inputKg : weight
            valueKG = manualInput ? inputKg : weight
            storageValue = strings.about.goalWeight.kg
        }
        localStorage.setItem('targetWeight', `${value} ${storageValue}`);
        amplitude.getInstance().logEvent('button_onboarding_goal_weight_tapped', {answer: value});
        const answer = new Map();
        answer.set("key", "userGoalWeight");
        answer.set("value", {value: valueKG, in_metrics: selected !== 1});
        nextStep(answer);
    }

    return (
        <div className={mainContainerClass}>
            <h1>{strings.about.goalWeight.title}</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>{strings.about.goalWeight.subtitle}</span>
            <SwitcherComponent options={options} value={selected} onChange={setSelected} />
            { manualInput ? (
                <>
                    <div className={`${styles.inputArea} flex-row`}>
                        { selected === 1 ? (
                            <AboutInput
                                checkResult={changeInputWeight}
                                onFocus={inputOnFocus}
                                onBlur={inputOnBlur}
                                currentValue={inputLbs}
                                inputType={strings.about.goalWeight.lbs}
                                min={88}
                                max={441}
                            />
                        ) : (
                            <AboutInput
                                checkResult={changeInputWeight}
                                onFocus={inputOnFocus}
                                onBlur={inputOnBlur}
                                currentValue={inputKg}
                                inputType={strings.about.goalWeight.kg}
                                min={40}
                                max={200}
                            />
                        )
                        }
                    </div>
                    { errorMessage && (<p className={styles.errorMessage}>{errorMessage}</p>) }
                    <ContinueButton
                        customStyles={buttonStyles}
                        nextStep={clickContinue}
                        disableButton={disableButton}
                        theme={themeData.continueButton}
                    />
                </>
                ) : (
                    <>
                        <h1 className={styles.weightTitle}>{Math.trunc(weight)}</h1>
                        <span className={styles.weightSubtitle}>{options.find(el => el.value === selected).text}</span>
                        <div className={styles.pickerContainer}>
                            <Picker data={pickerData} currentValue={70} convertedValue={pickerWeight} swipeValue={changePickerWeight}/>
                            <ContinueButton
                                nextStep={clickContinue}
                                disableButton={disableButton}
                                theme={themeData.continueButton}
                            />
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default GoalWeight;