import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

import styles from "./CheckboxCard.module.scss";
import {useEffect, useState} from 'react';
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    position: 'relative',
    width: 20,
    height: 20,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#FFFFFF',
    '.Mui-focusVisible &': {
      outline: '1px solid #BBBBBB',
    },
}));
  
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
    backgroundImage: 'linear-gradient(334.65deg, #DD3787 5.23%, #FD6C59 83.78%)',
    boxShadow: '0px 0px 2px rgba(225, 87, 104, 0.7)',
    '&:before': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
        "url('/images/icons/check-icon.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});

const PurpleBpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#7D8BF6',
    backgroundImage: '#7D8BF6',
    boxShadow: '0px 0px 2px rgba(202, 207, 247, 0.79)',
    '&:before': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
            "url('/images/icons/check-icon.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});

const LightPurpleBpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#B826F6',
    backgroundImage: '#B826F6',
    boxShadow: '0px 0px 2px rgba(202, 207, 247, 0.79)',
    '&:before': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 10,
        height: 8,
        transform: 'translate(-50%, -50%)',
        backgroundImage:
            "url('/images/icons/check-icon.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '10px 8px',
        content: '""',
    },
});

const checkedStyledIcon = (color) => {
    switch(color) {
        case "pink":
            return <BpCheckedIcon />
        case "purple":
            return <PurpleBpCheckedIcon />
        case "lightPurple":
            return <LightPurpleBpCheckedIcon />
        default:
            return <BpCheckedIcon />
    }
}


const CheckboxCard = props => {
    const {
        data,
        selectCard,
        smallCard,
        withSubtitle,
        cardStyles,
        checkboxColor,
        isActive
    } = props;
    const [card, setCard] = useState(data);
    const textBox = strings[card.section][card.page].labels[card.localID]

    useEffect(() => {
        if (!isActive)
            return
        setCard({...card, checked: isActive !== -1 })
    }, [isActive])

    const checkCard = (e) => {
        setCard({...card, checked: e.target.checked})
        selectCard({...card, checked: e.target.checked});
    }

    const clickCard = () => {
        setCard({...card, checked: !card.checked})
        selectCard({...card, checked: !card.checked});
    }

    const cardContainerActiveClass = `${styles.activeCard} ${styles[`${checkboxColor}ActiveCard`]}`
    const cardContainerClass = `${styles.cardContainer} ${card.checked ? cardContainerActiveClass : ''} ${smallCard ? styles.smallCardContainer : ''} flex-between`

    return (
        <div className={cardContainerClass}
             onClick={clickCard}
             style={cardStyles}
        >
            <div className='flex-row'>
                {!card.withoutImage && (
                    <LazyImage
                        className={styles.cardIcon}
                        srcWebp={card.iconWebp}
                        src={card.icon}
                        alt=""
                    />)
                }
                { withSubtitle ? (
                    <div className={`${card.withoutImage ? '' : styles.cardTitle} cardTitle ${styles.cardTitleWithSubtext}`}>
                        <p className={styles.cardText}>{textBox.text}</p>
                        <p className={styles.cardSubtext}>{textBox.subtext}</p>
                    </div>
                ) : (
                    <p className={`${card.withoutImage ? '' : styles.cardTitle} cardTitle`}>{textBox}</p>
                )}

            </div>
            <Checkbox
                sx={{
                    '&:hover': { bgcolor: 'transparent' },
                }}
                disableRipple
                checked={card.checked}
                checkedIcon={checkedStyledIcon(checkboxColor)}
                icon={<BpIcon />}
                onChange={e => checkCard(e)}
                {...props}
            />
        </div>
    )
}   

export default CheckboxCard;