import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./GoalBanner.module.scss";
import LazyImage from "../../UI/lazyImage/LazyImage";
import LabelWithCheckbox from "../../UI/labelWithCheckbox/LabelWithCheckbox";

const GoalBanner = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.goalBanner;
    const textInfo = strings.goal.goalBanner;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_one_million_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_one_million_continue_tapped');
        nextStep();
    }

    return (
        <div className={mainContainerClass}>
            { pageInfo.withUpImage &&
                (<LazyImage
                    className={styles.absoluteBackground}
                    src="/images/goals/banner-background-up-ny.png"
                    srcWebp="/images/goals/banner-background-up-ny.webp"
                    alt=""
                />)
            }
            <img loading="lazy" className={styles.logoImage} src="/images/Logo.svg" alt="" />
            <h1
                className={styles.title}
                style={{color: themeData.titleColor}}
            >
                {textInfo.bannerTitle}
            </h1>
            <p className={styles.subtitle}>{textInfo.bannerSubtitle}</p>
            <LazyImage
                className={styles.imageBanner}
                srcWebp={pageInfo.imageBannerWebp}
                src={pageInfo.imageBanner}
                style={pageInfo.cardsImageStyle}
                alt=""
            />
            { pageInfo.withPoints && (
                <div className={styles.pointsBox} >
                    { pageInfo.pointsData.map(point =>
                        (<LabelWithCheckbox key={point.id} theme={themeData.checkboxCardColor} labelText={textInfo.points[point.textKey]} />)
                    )}
                </div>
            )}
            <ContinueButton
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default GoalBanner;