import { useEffect } from "react";
import amplitude from 'amplitude-js';
import busyData from "../../../core/static/lifestyle/busy.json";
import useJsonParse from "../../../hooks/useJsonParse";
import TextCard from "../../UI/textCard/TextCard";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./Busy.module.scss";

const Busy = props => {
    const { nextStep, data } = props
    const pageInfo = data.settings.busy
    const textInfo = strings.lifestyle.busy
    const themeData = data.themeData
    const parsedData = useJsonParse(busyData);
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
        amplitude.getInstance().logEvent('screen_onboarding_busy_appeared');
    }, [])

    const selectCard = card => {
        amplitude.getInstance().logEvent('button_onboarding_busy_tapped', {answer: card.title});
        const answer = new Map();
        answer.set("key", "busy");
        answer.set("value", card.title);
        nextStep(answer);
    }

    function cardPadding(id){
        if(pageInfo.cardsWithPadding && id < 2)
            return 115
        else
            return null
    }

    return (
        <div className={mainContainerClass}>
            <h1 className={styles.title}>{textInfo.title}</h1>
            <span className={`${styles.marginTitle} componentSubtitle`}>{textInfo.subtitle}</span>
            { parsedData?.map(card => ( 
                <div key={card.id} className={styles.cardContainer}>
                    <TextCard
                        card={card}
                        selectCard={selectCard}
                        cardHeight={65}
                        nextStep={nextStep}
                        borderColor={themeData.checkboxCardColor}
                        rightPadding={cardPadding(card.id)}
                    />
                </div> )) 
            }
        </div>
    )
}   

export default Busy;